import classNames from 'classnames';
import {Heading, Image, Paragraph, Stack, Surface} from '@halp/ui';
import {CSSVariants} from '@halp/util/css';
import style from './PhoneMessage.module.css';
import type {ComponentProps} from 'react';

interface Props {
	from: string;
	text: string;
	image: ComponentProps<typeof Image>['src'];
	position: 'left' | 'right';
}

export function PhoneMessage({from, text, image, position}: Props) {
	const stackClasses = classNames(
		style.PhoneMessage,
		CSSVariants(style, 'PhoneMessage', position),
	);

	const mediaClasses = classNames(
		style.Media,
		CSSVariants(style, 'PhoneMessageMedia', position),
	);

	return (
		<Stack spacing="sm" className={stackClasses} fullWidth>
			<Image className={mediaClasses} src={image} alt="from" />
			<Surface
				color={position === 'left' ? 'purple' : 'white'}
				className={style.Content}
				padding="xs"
			>
				<Paragraph type="subtitle" color="blurple">
					{from}
				</Paragraph>
				<Heading type="h6" color="blurple">
					{text}
				</Heading>
			</Surface>
		</Stack>
	);
}
