import {getCountries} from './countries';
import type {CountryCode} from './types';

export async function getCountryFromCode(countryCode: CountryCode) {
	const countries = await getCountries();
	const countryBlob = countries.find(
		(countryData) => countryData.countryShortCode === countryCode,
	);
	return countryBlob?.countryName;
}
