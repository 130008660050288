import {type ReactNode, Component} from 'react';

interface Props {
	children: (
		next: VoidFunction | null,
		previous: VoidFunction | null,
		jumpTo: ((stepIndex: number) => void) | null,
	) => ReactNode;
}

export class Step extends Component<Props> {
	public override render() {
		return null;
	}
}
