import {type ReactNode, Component, Children} from 'react';
import {ProgressBar} from './components';
import type {Step} from './Step';

interface State {
	step: number;
}

interface Props {
	children: ReactNode;
	progressBar?: boolean;
	step?: number;
}

export class MultiStep<P extends Props, S extends State> extends Component<
	P,
	S
> {
	public constructor(props: P) {
		super(props);

		this.state = {step: props.step ?? 0} as S;
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.jumpTo = this.jumpTo.bind(this);
	}

	public override render() {
		const childrenArray = Children.toArray(this.props.children).filter(
			(step) => step != null,
		);
		const child = childrenArray[this.state.step];

		return (
			<>
				{this.props.progressBar ? (
					<ProgressBar
						currentStep={this.state.step}
						totalSteps={childrenArray.length}
					/>
				) : null}
				{(child as unknown as Step).props.children(
					this.state.step < childrenArray.length - 1 ? this.next : null,
					this.state.step === 0 ? null : this.previous,
					this.jumpTo,
				)}
			</>
		);
	}

	protected next() {
		const nextStep = this.state.step + 1;
		const step =
			typeof this.props.children === 'function'
				? nextStep
				: Math.min(nextStep, Children.count(this.props.children) - 1);
		this.setState({
			step,
		});
	}

	protected previous() {
		this.setState({
			step: Math.max(this.state.step - 1, 0),
		});
	}

	protected jumpTo(stepIndex: number) {
		this.setState({step: stepIndex});
	}
}
