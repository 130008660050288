import {useI18n} from '@halp/foundation/i18n';
import {Heading, Link, Stack} from '@halp/ui';
import {
	FormButton,
	FormField,
	PasswordField,
	RESTForm,
} from '@halp/foundation/Form';
import {SESSION_DOMAIN} from '@halp/foundation/env';
import {Paths} from '@halp/foundation/Paths';

interface Props {
	onLogin: ({
		data: {token, renew_token},
	}: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		data: {token: string; renew_token: string};
	}) => void;
	redirect?: string;
}

export function LoginForm({onLogin, redirect}: Props) {
	const {t} = useI18n();

	return (
		<>
			<Heading type="h2" weight="medium" spacing="md">
				{t('signin')}
			</Heading>
			<RESTForm
				defaultValues={{
					user: {
						email: '',
						password: '',
					},
				}}
				path={SESSION_DOMAIN}
				onSuccess={onLogin}
				redirect={redirect}
				withToast={false}
			>
				<FormField
					placeholder={t('email')}
					name="user.email"
					type="email"
					rules={{
						required: 'Email is required',
					}}
				/>
				<PasswordField name="user.password" placeholder={t('password')} />
				<Stack spacing="sm" justifyContent="flex-start">
					<FormButton>{t('signin')}</FormButton>
					<Heading>
						<Link color="primary" href={Paths.signup.url()}>
							{t('sign_up')}
						</Link>
					</Heading>
					<Heading>
						<Link href={Paths.login.reset.url()}>{t('forgot_password')}</Link>
					</Heading>
				</Stack>
			</RESTForm>
		</>
	);
}
