import {useCallback} from 'react';
import {useToast} from '@halp/ui';
import {useSession} from '../Users/UserProvider';
import {Form, type Props as FormProps} from './Form';
import {ResponseError} from './ResponseError';
import type {FieldValues, UseFormSetError} from 'react-hook-form';

type FormMethod = 'POST' | 'PUT' | 'DELETE';

export interface Props<Vars extends FieldValues, Data>
	extends FormProps<Vars, Data> {
	path: string;
	method?: FormMethod;
	headers?: {[key: string]: string};
	withToast?: boolean;
}

export function RESTForm<Vars extends FieldValues, Data>({
	path,
	method = 'POST',
	headers,
	onSubmit,
	withToast = true,
	onError,
	...props
}: Props<Vars, Data>) {
	const {hasRenewToken, refreshSession} = useSession();
	const addToast = useToast();
	const restSubmit = useCallback(
		async (vars: Vars, setError: UseFormSetError<Vars>) => {
			if (path == null) {
				// eslint-disable-next-line no-empty-function
				return new Promise(() => {});
			}

			if (onSubmit) {
				onSubmit(vars);
			}

			const formData = new FormData();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const jsonData = {} as any;
			let files = false;

			for (const key in vars) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				if ((vars[key] as any) instanceof File) {
					files = true;
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					formData.append(key, vars[key] as any);
				} else {
					jsonData[key] = vars[key];
				}
			}

			const parse = (responses: Response[]): Promise<Data[]> => {
				const result: Promise<Data>[] = [];
				for (const response of responses) {
					const contentType = response.headers.get('content-type');
					if (!contentType || contentType.indexOf('application/json') === -1) {
						if (withToast) addToast({type: 'error'});
						// TODO: @jtsmills: just throw and catch in form and call onError
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						setError('server' as any, {
							type: 'manual',
							message: 'Something has gone wrong',
						});
						return Promise.reject(
							new ResponseError({
								message: response.statusText,
								statusCode: response.status,
							}),
						);
					}

					if (response.status === 401) {
						if (hasRenewToken) {
							return refreshSession().then(async () => {
								const retryResponses = await Promise.all(promises());
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								return parse(retryResponses as any);
							});
						} else {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							setError('server.email' as any, {
								type: 'manual',
								message: 'Incorrect email or password',
							});
							return Promise.reject(
								new ResponseError({
									message: response.statusText,
									statusCode: response.status,
								}),
							);
						}
					}

					if (response.ok) {
						if (withToast) addToast({type: 'success'});
						result.push(response.json() as Promise<Data>);
					} else {
						if (withToast) addToast({type: 'error'});

						response.json().then((data) => {
							onError?.(data?.errors);
						});

						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						setError('server' as any, {
							type: 'manual',
							message: 'Something has gone wrong',
						});
						return Promise.reject(
							new ResponseError({
								message: response.statusText,
								statusCode: response.status,
							}),
						);
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return Promise.resolve(Promise.all(result)) as any;
			};

			const complete = (data: Data[]) => {
				if (data.length === 1) {
					return data[0];
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return data as any;
			};

			const promises = () => {
				const formFetch = fetch(path, {
					body: JSON.stringify(jsonData),
					credentials: 'include',
					headers: {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'Content-Type': 'application/json',
						...headers,
					},
					method,
				});

				if (files) {
					const fileFetch = fetch(path, {
						body: formData,
						credentials: 'include',
						headers,
						method,
					});

					return [formFetch, fileFetch];
				}

				return [formFetch];
			};

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return Promise.resolve(Promise.all(promises()) as any)
				.then(parse)
				.catch()
				.then(complete);
		},
		[
			path,
			onSubmit,
			withToast,
			addToast,
			hasRenewToken,
			refreshSession,
			onError,
			headers,
			method,
		],
	);

	return <Form {...props} handleSubmit={restSubmit} />;
}
