import {useState} from 'react';
import {useI18n} from '@halp/foundation/i18n';
import {RESTForm, FormField, FormButton} from '@halp/foundation/Form';
import {Paths} from '@halp/foundation/Paths';
import {API_DOMAIN} from '@halp/foundation/env';
import {MultiStep, Step} from '@halp/ui/MultiStep';
import {Stack, Link, Heading, Paragraph} from '@halp/ui';

export function ResetForm() {
	const [userEmail, setUserEmail] = useState<string>();
	const {t} = useI18n();

	return (
		<>
			<Heading type="h2" weight="medium" spacing="md">
				{t('reset_password.title')}
			</Heading>
			<MultiStep>
				<Step>
					{(next) => {
						return (
							<RESTForm
								defaultValues={{
									user: {
										email: '',
									},
								}}
								path={`${API_DOMAIN}/users/request_reset`}
								onSuccess={next ?? undefined}
								onSubmit={({user: {email}}) => {
									setUserEmail(email);
								}}
							>
								<FormField
									placeholder={t('email')}
									name="user.email"
									type="email"
									rules={{
										required: 'Email is required',
									}}
								/>
								<Stack spacing="sm" justifyContent="flex-start">
									<FormButton type="submit">
										{t('reset_password.reset')}
									</FormButton>
									<Heading type="h6">
										<Link href={Paths.login.index.url()}>{t('cancel')}</Link>
									</Heading>
								</Stack>
							</RESTForm>
						);
					}}
				</Step>
				<Step>
					{(_next) => {
						return (
							<>
								<Heading type="h5" spacing="md">
									{t('reset_password.success.title')}
								</Heading>
								<Paragraph>
									{t('reset_password.success.description', {
										email: userEmail,
									})}
								</Paragraph>
							</>
						);
					}}
				</Step>
			</MultiStep>
		</>
	);
}
