import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {useRouter} from 'next/router';
import {useI18n} from '@halp/foundation/i18n';
import {Button, Link, Image, Heading, Stack, TextSwap} from '@halp/ui';
import {Paths} from '@halp/foundation/Paths';
import {buildQueryString} from '@halp/util/path';
import {imgLogo, imgNamka, imgVictoria} from '@halp/images';
import {Phone} from '../Phone';
import style from './LoginLayout.module.css';
import type {ReactNode} from 'react';

interface Props {
	children: ReactNode;
	backURL?: string;
}

export function LoginLayout({children, backURL}: Props) {
	const {t} = useI18n();
	const {query} = useRouter();

	const backButton = backURL ? (
		<Link href={backURL} className={style.BackButton}>
			<Button icon={faChevronLeft} color="secondary" /> {t('go_back')}
		</Link>
	) : null;

	const queryParams = query && buildQueryString(query);
	const marketingUrl = queryParams
		? `${Paths.marketing.home}/?${queryParams}`
		: Paths.marketing.home;

	return (
		<div className={style.Page}>
			<div className={style.Container}>
				<div className={style.TopBar}>
					<Link href={marketingUrl}>
						<Image
							alt="Halp Logo"
							loading="eager"
							className={style.Logo}
							src={imgLogo}
						/>
					</Link>
				</div>
				{backButton}
				<div className={style.Content}>{children}</div>
			</div>
			<div className={style.Sidebar}>
				<div className={style.Blurs}>
					<div className={style.Blur} />
					<div className={style.Blur2} />
					<div className={style.Blur3} />
				</div>
				<Stack
					justifyContent="center"
					alignItems="center"
					direction="column"
					className={style.Hero}
				>
					<Heading
						className={style.HeroTitle}
						align="center"
						color="white"
						type="h1"
					>
						{t('login.hero.title')}
					</Heading>
					<Heading
						className={style.HeroSubtitle}
						align="center"
						color="pink"
						type="h2"
					>
						<TextSwap
							texts={[
								t('login.hero.subtitle1'),
								t('login.hero.subtitle2'),
								t('login.hero.subtitle3'),
								t('login.hero.subtitle4'),
								t('login.hero.subtitle5'),
							]}
							duration={3}
						/>
					</Heading>
				</Stack>
				<Phone
					className={style.Phone}
					messages={[
						{
							from: t('login.phone.coach_name'),
							text: t('login.phone.message_1'),
							image: imgNamka,
							position: 'left',
						},
						{
							from: t('login.phone.student_name'),
							text: t('login.phone.message_2'),
							image: imgVictoria,
							position: 'right',
						},
						{
							from: t('login.phone.coach_name'),
							text: t('login.phone.message_3'),
							image: imgNamka,
							position: 'left',
						},
						{
							from: t('login.phone.coach_name'),
							text: t('login.phone.message_4'),
							image: imgNamka,
							position: 'left',
						},
						{
							from: t('login.phone.student_name'),
							text: t('login.phone.message_5'),
							image: imgVictoria,
							position: 'right',
						},
					]}
				>
					<Heading color="white">{t('login.phone.cta')}</Heading>
				</Phone>
			</div>
		</div>
	);
}
