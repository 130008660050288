import classNames from 'classnames';
import {Stack} from '@halp/ui';
import style from './Phone.module.css';
import {PhoneMessage} from './components/PhoneMessage';
import type {ComponentProps, ReactNode} from 'react';

interface Props {
	className: string;
	messages: ComponentProps<typeof PhoneMessage>[];
	children?: ReactNode;
}

export function Phone({className, messages, children}: Props) {
	return (
		<div className={classNames(style.Phone, className)}>
			<div className={style.VolumeUp} />
			<div className={style.VolumeDown} />
			<div className={style.Power} />
			<Stack
				className={style.Screen}
				justifyContent="flex-start"
				direction="column"
				spacing="md"
				fullWidth
			>
				{messages.map((message, index) => (
					<PhoneMessage key={index} {...message} />
				))}
				{children}
			</Stack>
		</div>
	);
}
