import Cookies from 'universal-cookie';
import {useState, useEffect} from 'react';

const NODE_ENV = process?.env.NODE_ENV ?? 'development';
const COOKIE_DOMAIN =
	NODE_ENV === 'development' ? '.localhost:4000' : '.halp.co';
const HALP_REFER_COOKIE = 'referralCookie';

export function useReferrer() {
	const [referrer, setReferrer] = useState<string | null>(null);

	useEffect(() => {
		const cookie = new Cookies();
		if (document) {
			const docReferrer = document.referrer;
			const internalRefer = docReferrer === '' || docReferrer.includes('halp');
			if (internalRefer) {
				setReferrer(cookie.get(HALP_REFER_COOKIE));
			} else {
				cookie.set(HALP_REFER_COOKIE, docReferrer, {
					path: '/',
					sameSite: 'strict',
					domain: COOKIE_DOMAIN,
				});
				setReferrer(docReferrer);
			}
		}
	}, []);
	return referrer;
}
