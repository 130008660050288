import {useState} from 'react';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {useI18n} from '@halp/foundation/i18n';
import {useReferrer, useSession} from '@halp/foundation/Users';
import {
	useTimezoneInfo,
	PINNED_COUNTRIES,
	getCountryFromCode,
	useCountryNames,
} from '@halp/util';
import type {CountryCode} from '@halp/util';
import {isHyperLink} from '@halp/util/string/is-hyperlink';
import {API_DOMAIN} from '@halp/foundation/env';
import {Grid, Heading, Icon, Link, Paragraph, Stack} from '@halp/ui';
import {
	FormButton,
	FormEmailField,
	FormErrorMessage,
	FormField,
	FormPhoneField,
	FormSelect,
	PasswordField,
	RESTForm,
} from '@halp/foundation/Form';
import {Paths} from '@halp/foundation/Paths';
import style from './SignUpForm.module.css';

const SIGNUP_API = `${API_DOMAIN}/users`;

/* eslint-disable @typescript-eslint/naming-convention */
interface TrackingParams {
	utm_source?: string;
	utm_medium?: string;
	utm_campaign?: string;
	utm_id?: string;
	utm_term?: string;
	utm_content?: string;
	gclid?: string;
}

interface Props {
	trackingParams?: TrackingParams;
	signupSlug?: string;
}

export function SignUpForm({trackingParams, signupSlug: signup_slug}: Props) {
	const {t} = useI18n();
	const {assignTokens} = useSession();
	const [uniquePhone, setUniquePhone] = useState(true);
	const [uniqueEmail, setUniqueEmail] = useState(true);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function onSuccess(data: any) {
		if (data.token && data.renew_token) {
			assignTokens({token: data.token, renewToken: data.renew_token});
		}
	}

	const referralSource = useReferrer();
	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const country = useTimezoneInfo(timezone)?.countryCode as CountryCode;
	const mappedCountry = getCountryFromCode(country);
	const countryNames = useCountryNames();

	function validateName(value: string) {
		if (isHyperLink(value)) {
			return t('validation.mixed.default');
		}
		return true;
	}

	return (
		<RESTForm
			path={SIGNUP_API}
			onSuccess={onSuccess}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onError={(errors: any) => {
				setUniquePhone(errors?.phone == null);
				setUniqueEmail(errors?.email == null);
			}}
			defaultValues={{
				user: {
					referral_source: referralSource,
					...trackingParams,
					timezone,
					country: mappedCountry || 'India',
					signup_slug: signup_slug ?? null,
				},
			}}
		>
			<Stack spacing="sm" justifyContent="space-between" alignItems="center">
				<Heading type="h2" spacing="lg" weight="medium">
					{t(`students.signup.title`)}
				</Heading>
				<Heading type="h6" spacing="lg" color="grey">
					{t('students.signup.or')}{' '}
					<Link color="primary" href={Paths.login.index.url()}>
						{t('students.signup.signin')}
					</Link>
				</Heading>
			</Stack>
			<Grid xl={2} gridGap="sm">
				<FormField
					name="user.first"
					placeholder={t('first_name')}
					rules={{
						required: t('validation.mixed.required'),
						validate: validateName,
					}}
				/>
				<FormField
					name="user.last"
					placeholder={t('last_name')}
					rules={{
						required: t('validation.mixed.required'),
						validate: validateName,
					}}
				/>
			</Grid>
			<FormSelect
				name="user.country"
				placeholder={t('country')}
				options={[
					...PINNED_COUNTRIES,
					...countryNames.filter(
						(country) => !PINNED_COUNTRIES.includes(country),
					),
				].map((country) => {
					return {label: country, value: country};
				})}
				rules={{required: t('validation.mixed.required')}}
			/>
			<FormEmailField
				name="user.email"
				placeholder={t('email')}
				rules={{
					required: t('validation.mixed.required'),
				}}
			/>
			{!uniqueEmail && (
				<FormErrorMessage>{t('validation.email.exists')}</FormErrorMessage>
			)}
			<Stack spacing="sm" alignItems="center">
				<Icon color="green" icon={faWhatsapp} size="2x" />
				<FormPhoneField
					name="user.phone"
					placeholder={t('students.signup.phone_field')}
					timezone={timezone}
					className={style.PhoneFormField}
				/>
			</Stack>
			<Paragraph color="grey" type="subtitle2" spacing="md">
				{t('students.signup.whatsapp_subtitle')}
			</Paragraph>
			{!uniquePhone && (
				<FormErrorMessage>{t('validation.phone.valid')}</FormErrorMessage>
			)}
			<PasswordField name="user.password" placeholder={t('password')} />
			<FormButton fullWidth padding="md" thin={false} className={style.SignUp}>
				{t('students.signup.cta')}
			</FormButton>
		</RESTForm>
	);
}
