import {useEffect, useState} from 'react';
import type {CSSProperties} from 'react';

interface Props {
	texts: string[];
	duration: number;
}

function buildAnimationStyles(duration: number) {
	return {
		animationDuration: `${duration}s`,
		animationName: 'fade-in-and-out',
		animationTimingFunction: 'var(--ease-out-quad)',
	};
}

export function TextSwap({texts, duration}: Props) {
	const style = buildAnimationStyles(duration);
	const [stringIndex, setStringIndex] = useState(0);
	const [animationStyle, setAnimationStyle] = useState<CSSProperties>(style);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setStringIndex((stringIndex + 1) % texts.length);
		}, duration * 1000);

		return () => {
			setAnimationStyle({});
			setAnimationStyle(style);
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [texts, stringIndex]);

	const string = texts[stringIndex];
	return (
		<span key={string} style={animationStyle}>
			{string}
		</span>
	);
}
