import {useState} from 'react';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {Stack, Icon} from '@halp/ui';
import {useI18n} from '../../../i18n';
import {FormField} from '../FormField';
import style from './Password.module.css';

interface Props {
	placeholder?: string;
	name: string;
	allowHiding?: boolean;
	label?: string;
}

export function PasswordField({
	placeholder,
	name,
	allowHiding = true,
	label,
}: Props) {
	const {t} = useI18n();
	const [hidden, setHidden] = useState(true);

	const toggle = allowHiding ? (
		<Icon
			icon={hidden ? faEye : faEyeSlash}
			onClick={() => setHidden(!hidden)}
		/>
	) : null;

	return (
		<Stack
			className={style.Password}
			alignItems={label ? 'center' : 'baseline'}
		>
			<FormField
				placeholder={placeholder}
				label={label}
				name={name}
				type={hidden ? 'password' : 'text'}
				rules={{
					required: t('validation.mixed.required'),
					minLength: {
						value: 8,
						message: t('validation.string.min', {min: 8}),
					},
					maxLength: {
						value: 50,
						message: t('validation.string.max', {max: 50}),
					},
				}}
			/>
			{toggle}
		</Stack>
	);
}
