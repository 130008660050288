import style from './ProgressBar.module.css';

export function ProgressBar({
	currentStep,
	totalSteps,
}: {
	currentStep: number;
	totalSteps: number;
}) {
	const percentage = (currentStep / totalSteps) * 100;

	return (
		<div className={style.Bar}>
			<div className={style.ProgressBar} style={{width: `${percentage}%`}} />
		</div>
	);
}
